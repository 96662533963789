import React from 'react'
import logo from './logo.svg'

import {
  createStyles,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

import Header from './components/Header'
import Edit from './Edit/Edit'

const useStyles = makeStyles(() =>
  createStyles({
    view: {
      padding: '15px',
    },
  })
)

const App: React.FC = () => {
  const classes = useStyles()
  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className={classes.view}>
        <Edit />
      </div>
    </ThemeProvider>
  )
}

export default App
